import * as Sentry from "@sentry/vue";
import { Capacitor } from '@capacitor/core';

const dsns = {
  web: 'https://916b30e2125c4a0599bfb96b78d2d88a@errors.coredacao.com/4',
  android: 'https://05181bfcb8f148a2a3d2a0e4f4235a29@errors.coredacao.com/6',
  ios: 'https://714c8cb4c0f24d0eb888f1942840b78e@errors.coredacao.com/5',
}

export default defineNuxtPlugin((nuxt) => {
  const app = nuxt.vueApp;
  const router = useRouter();
  const platform = Capacitor.getPlatform() as keyof typeof dsns;

  Sentry.init({
    app,
    enabled: process.env.NODE_ENV === "production",
    dsn: dsns[platform],
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],

    tracesSampleRate: 1,
  });
});