import revive_payload_client_YTGlmQWWT5 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/vercel/path0/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/vercel/path0/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/vercel/path0/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_xKuw1km5sV from "/vercel/path0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/apps/client/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/vercel/path0/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/vercel/path0/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import motion_N0nXvikqD4 from "/vercel/path0/apps/client/plugins/motion.ts";
import oh_icons_OHI6vY36fS from "/vercel/path0/apps/client/plugins/oh-icons.ts";
import sentry_3AyO8nEfhE from "/vercel/path0/apps/client/plugins/sentry.ts";
import vue_query_wrmMkNpEpe from "/vercel/path0/apps/client/plugins/vue-query.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  chunk_reload_client_SeG0EjL5Ec,
  motion_N0nXvikqD4,
  oh_icons_OHI6vY36fS,
  sentry_3AyO8nEfhE,
  vue_query_wrmMkNpEpe
]